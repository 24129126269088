// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { ApiGatewayPathBuilder, AppEnginePathBuilder, Env, IPlatformAdminEnvironment } from 'shared-lib'

const env: Env = 'ci'
const pathBuilder = new ApiGatewayPathBuilder(env)

export const environment: IPlatformAdminEnvironment = {
  production: false,
  env,
  firebaseConfig: {
    apiKey: 'AIzaSyBJnAEalpY0inF_NHTc8vC2Gk5GFNHdqx4',
    authDomain: 'ci-employer.cnected.com',
    databaseURL: 'https://cnect-ci.firebaseio.com',
    projectId: 'cnect-ci',
    storageBucket: 'cnect-ci.appspot.com',
    messagingSenderId: '524673897104',
    appId: '1:524673897104:web:d7d6f8312271d7ba47fe15',
    measurementId: 'G-TBMJN6NER9',
  },

  services: {
    jobs: pathBuilder.build('jobs'),
    jobsAutomation: pathBuilder.build('jobsAutomation'),
    registration: pathBuilder.build('registration'),
    user: pathBuilder.build('user'),
    auth: pathBuilder.build('auth'),
    files: pathBuilder.build('files'),
    customer: pathBuilder.build('customer'),
    notifications: pathBuilder.build('notifications'),
  },

  // We can turn authentication off in case we are using this application inside and SDK and do not want to have any authentication
  authentication: true, // Look to the auth guard for use of this value
  themesEnabled: true,
  v2ReCaptcha: '6LcQh4scAAAAAGFHzKO0pMDwtTDA9cAquJaGJ8L8',
  tenantId: 'PLATFORM-USER-jlgac',
  algoliaAppId: 'W2R5M3E540',
  cdnCname: 'ci-content.cnected.com',
  version: 'bea9920',
  basePlatformHost: 'ci-admin.cnect.jobs',
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
